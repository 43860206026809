import { AbstractControl, ValidatorFn } from '@angular/forms';

export function cellphoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    if (!/^(9)\d{8}$/.test(value)) {
      if (!value.startsWith('9')) {
        return { startsWithNineInvalid: true };
      } else {
        return { cellphoneInvalid: true };
      }
    }
    return null;
  }
}