import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { environment } from '@environments/environment';
import { IAgencies } from '@common/interfaces/maps.interface';
import { UtilsService } from '@services/utils/utils';
import { AGENCIES_LIST_MOCK, KASNET_AGENCIES_URL_MOCK } from '@common/mocks/agencies.mocks';
import { Coordinates } from '@ionic-native/geolocation/ngx';
import { RipleyStore } from '@common/models/store-product';

type TypeBranch = {
  list: RipleyStore[]
  distance: string[]
}

@Injectable({
  providedIn: 'root'
})
export class AgenciesService {

  constructor(private http: HttpService) { }

  public async getAgencies(): Promise<IAgencies[]> {
    const url = environment.BASE_BACKEND_WORKER + `/hbk/managementapp/parameter/listagencylocation`;
    return await this.http.get(url, AGENCIES_LIST_MOCK).toPromise();
  }

  public async getKasnetAgencies() {
    const url = environment.BASE_BACKEND_WORKER + `/parameterhbk/CPKS`;
    return await this.http.get(url, KASNET_AGENCIES_URL_MOCK).toPromise();
  }

  public getNearestAgency(agencies: IAgencies[], currentLat: number, currentLong: number): TypeBranch {

    const mappedBranch = agencies.reduce<TypeBranch>((accu, item: IAgencies) => {

      const distance =  UtilsService.getDistanceFromLatLonInKm(
        {
          latitude: item.latitude,
          longitude: item.longitude
        } as Coordinates,
        {
          latitude: currentLat,
          longitude: currentLong,
        } as Coordinates
      );

      accu.list = accu.list.concat([{
        address: [item.address],
        latitude: item.latitude,
        longitude: item.longitude,
        name: item.name,
        attributes: [],
        distance: distance.toString(),
        rawDistance: 0,
        description: item.name,
        state: '',
        uniqueId: 'null',
      }]);
      accu.distance = accu.distance.concat([distance.toString()])
      return accu
    }, {
      list: [],
      distance: []
    });

    return mappedBranch;
  }

}