import { Injectable } from '@angular/core';
import { FirebaseService } from '@services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardCxService {

  constructor(private firebaseService: FirebaseService) { }
  
  async getPdfUrl(): Promise<string | null> {
    const collectionName = 'rpgoCatalog';
    const desiredId = 'pdfSupercanjes';
    
    try {
      const querySnapshot = await this.firebaseService.getFirebaseCollection(collectionName).get();

      if (!querySnapshot.empty) {
        for (const doc of querySnapshot.docs) {
          const documentId = doc.id;
          if (documentId === desiredId) {
            const data = doc.data(); 
            if (data && data.url) {
              return data.url;
            } else {
              console.log('El documento no tiene una URL válida.');
              return null;
            }
          }
        }
      } else {
        console.log('No se encontraron documentos en la colección:', collectionName);
        return null;
      }
    } catch (error) {
      console.error('Error al obtener documentos:', error);
      return null;
    }
  }

  async obtenerBannerText(category: string): Promise<string | null> {
    try {
      const querySnapshot = await this.firebaseService.getFirebaseCollection('productCategories')
        .where('categoryId', '==', category)
        .get();

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0]; 
        const data = doc.data();
        const bannerText = data.bannerText;
        return bannerText;
      } else {
        console.log('No se encontraron documentos en la categoría:', category);
        return null;
      }
    } catch (error) {
      console.error('Error al obtener el banner text:', error);
      return null;
    }
  }
}
