import { AbstractControl, ValidatorFn } from '@angular/forms';

export function validateAmountRange(minAmount: number, maxAmount: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        const value = parseFloat(control.value);
        if (isNaN(value)) {
          return { amountInvalid: true };
        } else {
          if (value < minAmount || value > maxAmount) {
            return { amountInvalid: true };
          } else {
            return null;
          }
        }
      };
}
