import { Coordinates } from "@ionic-native/geolocation/ngx";

export const DEFAULT_MAP_CENTER = { latitude: -12.0463731, longitude: -77.042754 } as Coordinates;

export const KUNDER_LOCATION = { latitude: -33.425420, longitude: -70.614120 } as Coordinates;

export const PUNTA_ARENAS_LOCATION = { latitude: -53.15483, longitude: -70.91129 } as Coordinates;

export const DISTANCE_CENTER_KUNDER = 2.456;

export const DISTANCE_CENTER_PUNTA_ARENAS = 2192;