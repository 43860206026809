import { Injectable } from '@angular/core';
import { IMenuOptions } from '@common/interfaces/menu-options.interface';
import { CHECK_BLACK_LIST_MOCK } from '@common/mocks/open-account.mocks';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';
import { NavigationCheckerService } from '@services/navigation/navigation-checker.service';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuOptionsService {

  menuOptions: Promise<IMenuOptions>;
  menuOptionsLoginCache: Promise<IMenuOptions>;

  constructor(
    private http: HttpService,
    private navCheckerSrv: NavigationCheckerService
  ) { }

  public checkMenuOptions(): Promise<IMenuOptions> {    
    if(this.navCheckerSrv.InHomeFromLogin() && this.menuOptionsLoginCache) {
      return this.menuOptionsLoginCache;
    }
    const url = environment.BASE_BACKEND_WORKER + '/admin/user/menu-options/authorization';
    const request = this.http.post(url, {}, CHECK_BLACK_LIST_MOCK).pipe(shareReplay(1)).toPromise();    

    if(this.navCheckerSrv.InLogin()){
      this.menuOptionsLoginCache = request;
      return this.menuOptionsLoginCache;
    } else {
      return request;
    }    
  }
}
