import { IFinantialInstitutionDetails } from "./transfers.interface";

export const detailSavingAccountModel:IDetailsSavingAccount = {
  account: '',
  cci: '',
  symbolCurrency: '',
  codeCurrency: '',
  totalAmount: 0,
  availableAmount: 0,
  productType: '',
  productName: '',
  cardNumber: '',
  type: '',
  benefits: '',
  footerLabel: '',
  hashAccount: '',
  hashCCI: '',
  hasRestrictions: false,
  statusAccount: '',
  isAvailableForInterbankTransfer: false,
  isAvailableForOwnAccountsTransfer: false,
  isAvailableForThirdPartiesTransfer: false,
  isAvailableForImmediateInterbankTransfer: false,
  isAvailableForOwnAccountsTransferAsDestiny: false,
  isAvailableForInteroperabilityTransfer: false,
}

export interface IContactDeviceList{
  name: string;
  phoneNumber: string;
  familyName: string;
}

export interface IDetailsSavingAccount {
  account: string;
  cci: string;
  symbolCurrency: string;
  codeCurrency: string;
  totalAmount: number;
  availableAmount: number;
  productType: string;
  productName: string;
  cardNumber: string;
  type?: string;
  benefits?: string;
  footerLabel: string;
  hashAccount?: string;
  hashCCI?: string;
  hasRestrictions?: boolean;  
  statusAccount: string;
  isAvailableForInterbankTransfer: boolean;
  isAvailableForOwnAccountsTransfer: boolean;
  isAvailableForImmediateInterbankTransfer: boolean;
  isAvailableForThirdPartiesTransfer: boolean;
  isAvailableForOwnAccountsTransferAsDestiny: boolean;
  isAvailableForInteroperabilityTransfer: boolean;
}

export interface IContactDeviceList{
  name: string;
  phoneNumber: string;
  familyName: string;
}

export interface IMovementSavingAccount {
  operationDate: string;
  amount: number;
  description: string;
  symbolCurrency: string;
  movementIndicator: string;
}


export interface IPdfUrlSavings {
  id: number;
  name: string;
  description: string;
  value: string;
}
